<template>
  <g :fill="color" fill-rule="nonzero">
    <path
      d="M4.25 9.245h6.5a.75.75 0 1 0 0-1.5h-6.5a.75.75 0 0 0 0 1.5zM4.25 12.457h6.5a.75.75 0 0 0 0-1.5h-6.5a.75.75 0 1 0 0 1.5z"
    />
    <path
      d="M12.917 16.74H2.083A1.827 1.827 0 0 1 .25 14.92V2.07c0-1.007.823-1.82 1.833-1.82h10.834c1.01 0 1.833.813 1.833 1.82v12.85c0 1.008-.823 1.82-1.833 1.82zm0-1.5a.327.327 0 0 0 .333-.32V2.07a.327.327 0 0 0-.333-.32H2.083a.327.327 0 0 0-.333.32v12.85c0 .175.147.32.333.32h10.834z"
    />
    <path d="M4.25 6.033h4.333a.75.75 0 1 0 0-1.5H4.25a.75.75 0 0 0 0 1.5z" />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
